 export const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };


  export const subjects = [
    {
      name: "COMMERCE",
    },
    {
      name: "AGRICULTURAL SCIENCE"
    },
    {
      name: "BIOLOGY"
    },
    {
      name: "CHEMISTY"
    },
    {
      name: "CHRISTAIN RELIGIOUS STUDIES"
    },
    {
      name: "CIVIC EDUCATION"
    },
    {
      name: "COMPUTER STUDIES"
    },
    {
      name: "DATA PROCESSING"
    },
    {
      name: "ECONOMICS"
    },
    {
      name: "ENGLISH LANGUAGE"
    },
    {
      name: "FINANCIAL ACCOUNTING"
    },
    {
      name: "FURTHER MATHEMATICS"
    },
    {
      name: "GEOGRAPHY"
    },
    {
      name: "GOVERNMENT"
    },
    
    {
      name: "HAUSA"
    },
    {
      name: "HISTORY"
    },
    {
      name: "HOME MANAGEMENT"
    },
    {
      name: "IGBO"
    },
    
    {
      name: "ISLAMIC RELIGIOUS STUDIES"
    },
    {
      name: "LITERATURE IN ENGLISH"
    },
    {
      name: "MARKETING"
    },
    {
      name: "MATHEMATICS"
    },
    {
      name: "PHYSICS"
    },
    {
      name: "YORUBA"
    },
    {
      name: "FOOD AND NUTRITION"
    },
    {
      name: "FRENCH"
    },
    {
      name: "TEACHING PRACTICE"
    },
    {
      name: "EDUCATION"
    },
    {
      name: "INTEGRATED SCIENCE"
    },
    {
      name: "PHYSICAL HEALTH EDUCATION (P.H.E)"
    },
    {
      name: "SOCIAL STUDIES"
    },
    
  ]

  export const grading = [
    {
      name:"A1"
    },
    {
      name:"B2"
    },
    {
      name:"B3"
    },
    {
      name:"C4"
    },
    {
      name:"C5"
    },
    {
      name:"C6"
    },
    {
      name:"D7"
    },
    {
      name:"E8"
    },
    {
      name:"F9"
    },
  ]