import { numberWithCommas } from "../../../common";
import useAdmission from "../../../hooks/useAdmission";
import useProspective from "../../../hooks/useProspective";
import "./Style.css";

const ApprovedPaymentComponent = ({
  loading,
  btnText,
  handlePayment,
  acceptanceFee,
}) => {
  const { data } = useAdmission();
  const {data:datas} = useProspective()
  return (
    <>
    {data && 
    
    <div>
      <div class="card p-5" style={{ padding: "10px" }}>
        <div>
          <p class="business">{acceptanceFee?.name && "Acceptance Fee payment"}</p>
        </div>
        <div class="col-md-6">
            <label>Application Number</label>
            <h6>{data?.username}</h6>
        </div>
        <div class="col-md-6">
            <label>Payer</label>
            <h6>{data?.surname} {data?.othername}</h6>
        </div>
        <div class="col-md-6">
            <label>Email Address</label>
            <h6>{data?.email}</h6>
        </div>
        <div class="col-md-6">
            <label>Session</label>
            <h6>{data?.entrySession}</h6>
        </div>
        <div class="pricing p-3 rounded mt-4 mb-4 d-flex justify-content-between">
          <div class="images d-flex flex-row align-items-center">
            <div class="d-flex flex-column ml-4">
              <span class="business">Amount</span>
              <span class="plan">{acceptanceFee?.amountInWords}</span>
            </div>
          </div>
          <div class="d-flex flex-row align-items-center">
            <sup class="dollar font-weight-bold">₦</sup>
            <span class="amount ml-1 mr-1">
              {acceptanceFee && numberWithCommas(acceptanceFee?.amount)}
            </span>
          </div>
        </div>
        <div class="mt-3">
          <button
            class="btn btn-primary btn-block btn-raised waves-effect payment-button"
            onClick={handlePayment}
            disabled={loading}
          >
            {btnText} <i class="fa fa-long-arrow-right"></i>
          </button>
        </div>
      </div>
    </div>
    }
  </>
  );
};

export default ApprovedPaymentComponent;
