import React, { useState } from "react";
import { useEffect } from "react";
import api from "../../../api/api";
import {
  GetApplicantDetails,
  GetApplicationFee,
  GetPaymentStatus,
  InitiateApplicationPayment,
  InitiatemedicalfeePayment,
  InitiateResultCheckingPayment,
} from "../../../api/application";
import { getCurrentUser } from "../../../components/services/auth/authService";
import { toast } from "react-toastify";
import ApplicantPayment from "../components/ApplicantPayment";
import { useNavigate } from "react-router-dom";
import ResultPayment from "../components/ResultPayment";

function ApplicationPayment() {
  const [admissionFee, setAdmissionFee] = useState();
  const [resultFee, setResultFee] = useState()
  const [loading, setLoading] = useState(false);
  const [btnText, setBtnText] = useState("Proceed to payment");
  const [applicationFeeStatus, setApplicationFeeStatus] = useState('')
  const [resultFeeStatus, setresultFeeStatus] = useState('')

  const user = getCurrentUser();

  const navigate = useNavigate()

  const errorProp = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const warnProp = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const successProp = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  // const getPaymentDetails = async () => {
  //   try {
  //     const { data } = await GetApplicationFee();
  //     console.log(data);
  //     console.log("Let's see", data[0]);
  //     setAdmissionFee(data[0]);
  //     setResultFee(data[1])
  //   } catch (error) {}
  // };
  const getPaymentInfo = async () => {
    try {
      const { data } = await api.post('/admissions/get-payment', {
        payment_name: 'application fee'
      });
      console.log("Info oh",data.data);
      setAdmissionFee(data.data);
    } catch (error) {}
  };

  const fetchPaymentStatus = async () => {
    try {
      const { data } = await GetPaymentStatus(user.application_id);
      console.log(data, "statuss");
      console.log(data.application_fee, "statusssass");
      setApplicationFeeStatus(data.application_fee.payment_status)
      console.log(data.result_checking_fee, "checking fee");
      setresultFeeStatus(data.result_checking_fee.payment_status)
      console.log(data.medical_fee, "checkingmedical fee");
      setresultFeeStatus(data.medical_fee.payment_status)
      
    } catch (error) {}
  };

  const payApplicationFee = async () => {
    try {
      setBtnText("Initializing...")
      setLoading(true);
      const { data } = await InitiateApplicationPayment({
        application_id: user.application_id,
      });
      console.log("payment oh", data);
      const {status, authorization_url, reference} = data
      if (status && status === "success") {
        toast.info("Payment already made", warnProp)
        setLoading(false)
        setBtnText("Proceed to payment")
      } else {
        toast.success("Initiating payment", successProp);
        window.location.replace(authorization_url);
        setLoading(false);

      }
    } catch (error) {
      console.log(error);
      setBtnText("Failed, try again")
      setLoading(false);
      toast.error("Initiating payment failed!", errorProp);
    }
  };

  const paymedicalFee = async () => {
    try {
      setBtnText("Initializing...")
      setLoading(true);
      const { data } = await InitiatemedicalfeePayment({
        application_id: user.application_id,
      });
      console.log("payment oh", data);
      const {status, authorization_url, reference} = data
      if (status && status === "success") {
        toast.info("Payment already made", warnProp)
        setLoading(false)
        setBtnText("Proceed to payment")
      } else {
        toast.success("Initiating payment", successProp);
        window.location.replace(authorization_url);
        setLoading(false);

      }
    } catch (error) {
      console.log(error);
      setBtnText("Failed, try again")
      setLoading(false);
      toast.error("Initiating payment failed!", errorProp);
    }
  };

  const payResultCheckFee = async () => {
    try {
      setBtnText("Initializing...")
      setLoading(true);
      const { data } = await InitiateResultCheckingPayment({
        application_id: user.application_id,
      });
      console.log("payment oh", data);
      const {status, authorization_url, reference} = data
      if (status && status === "success") {
        toast.info("Payment already made", warnProp)
        setLoading(false)
        setBtnText("Proceed to payment")
      } else {
        toast.success("Initiating payment", successProp);
        window.location.replace(authorization_url);
        setLoading(false);

      }
    } catch (error) {
      console.log(error);
      setBtnText("Failed, try again")
      setLoading(false);
      toast.error("Initiating payment failed!", errorProp);
    }
  };

  useEffect(() => {
    fetchPaymentStatus()
    getPaymentInfo()
  }, []);

  return (
    <>
      <div class="page-wrapper"> 
        <div className="page-content-tab">
          <div class=" mb-5  justify-content-center">
            <div class="page-title-box">
              <div class="float-right">
                <ol class="breadcrumb">
                  {/* <li class="breadcrumb-item"><a href="javascript:void(0);">Metrica</a></li>
                            <li class="breadcrumb-item"><a href="javascript:void(0);">Pages</a></li> */}
                  <li class="breadcrumb-item active">Payment</li>
                </ol>
              </div>
              <h4 class="page-title">Payment</h4>
            </div>

            {((applicationFeeStatus !== "success")) &&  
            
            <ApplicantPayment
              loading={loading}
              btnText={btnText}
              handlePayment={payApplicationFee}
              admissionFee={admissionFee}
            />
          }

          {(applicationFeeStatus === "success") && 
          <div class="alert dspg-success" role="alert">
          Payments completed. Check your dashboard to print your receipt
        </div>
        }
          </div>
        </div>
      </div>
    </>
  );
}

export default ApplicationPayment;
